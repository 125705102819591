/* eslint-disable */
import React from "react";
import { connect } from "react-redux";

function UserProfile(props) {
  return <h3>User Profile page will be available soon.</h3>;
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
