import React from "react";
import M_ToolbarFilter from "./M_ToolbarFilter";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  toolbarFiltersLength,
  addToolbarFilter,
  resetToolbarFilters,
} from "../../../../../../store/actions/manageToolbarSelectionsActions";

const M_ToolToolbarFilterContainer = (props) => {
  const { templateId, crmId } = useParams();
  const toolbar = props.toolbar;
  const optionSets = props.optionSets;
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const dispatch = useDispatch();

  var ScopeFilters = [];
  ScopeFilters =
    toolbar &&
    toolbar.primary &&
    toolbar.primary.filters &&
    toolbar.primary.filters.filter(
      (item) => item.templateIds && item.templateIds.indexOf(templateId) > -1
    );
  React.useEffect(() => {
    if (ScopeFilters && selectedOptions) {
      if (
        ScopeFilters.length === selectedOptions.length ||
        ScopeFilters.length - 1 === selectedOptions.length
      ) {
        dispatch(addToolbarFilter([...selectedOptions]));
        dispatch(toolbarFiltersLength(ScopeFilters.length));
      }
    }
  }, [
    ScopeFilters &&
      selectedOptions &&
      selectedOptions.length > 0 &&
      ScopeFilters.length > 0,
  ]);

  const dispatchFilters = (selectedoption) => {
    setSelectedOptions((prevSelectedOptions) => {
      const remainOptions = prevSelectedOptions.filter(
        (option) => option.filterName !== selectedoption.filterName
      );
      let updatedSelectedOptionState = [...remainOptions, selectedoption];
      if (
        Object.keys(selectedoption).length > 0 &&
        ScopeFilters.length - 1 === updatedSelectedOptionState.length
      ) {
        dispatch(addToolbarFilter([...updatedSelectedOptionState]));
        dispatch(toolbarFiltersLength(ScopeFilters.length));
      } else if (ScopeFilters.length === updatedSelectedOptionState.length) {
        // let filteredSelectedOptions = [
        //   ...updatedSelectedOptionState.filter(
        //     (ele) => ele.filterName !== selectedoption.filterName
        //   ),
        //   selectedoption,
        // ];
        dispatch(addToolbarFilter(updatedSelectedOptionState));
        dispatch(toolbarFiltersLength(ScopeFilters.length));
        return updatedSelectedOptionState;
      }
      return updatedSelectedOptionState;
    });
  };

  React.useEffect(() => {
    dispatch(resetToolbarFilters());

    setSelectedOptions([]);
  }, [templateId]);
  //Dispatching Toolbar Filter in order to getting Filter Length
  // React.useEffect(() => {
  //   if (ScopeFilters && ScopeFilters.length > 0) {
  //     dispatch(toolbarFiltersLength(ScopeFilters.length));
  //   }
  // }, [ScopeFilters && ScopeFilters.length]);
  return (
    <React.Fragment key={templateId}>
      {toolbar &&
        ScopeFilters &&
        ScopeFilters.length > 0 &&
        ScopeFilters.map((pageFilter, index) => {
          return (
            <M_ToolbarFilter
              key={pageFilter.optionSetName}
              dispatchFilters={dispatchFilters}
              pageFiltersLength={ScopeFilters.length}
              indexValue={index}
              toolbar={toolbar}
              {...pageFilter}
              optionSets={optionSets}
              templateId={templateId}
              investmentId={crmId}
            />
          );
        })}
    </React.Fragment>
  );
};

M_ToolToolbarFilterContainer.propTypes = {
  toolbar: PropTypes.object,
  optionSets: PropTypes.array,
};
export default M_ToolToolbarFilterContainer;
