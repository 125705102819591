/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import M_CheckList from "../../../Molecules/Common/Checklist/M_CheckList";
import O_Mail from "../IRC_Mail/O_Mail";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import O_IRCSlots from "../IRCSlots/O_IRCSlots";
import { ircConfig, labelName } from "../../../../../Utils/config/config";

function O_CheckList(props) {
  const [bookIRC, setBookIRC] = React.useState(false);
  const { data: primaryData } = props.currentPage;

  const handleBookIRC = () => {
    setBookIRC(true);
  };
  const handleIRCForm = () => {
    setBookIRC(false);
  };
  const getReload = () => {
    props.reload();
  };
  return (
    <>
      {props?.checkListData?.checklistData?.length > 0 && (
        <div>
          {!props.hideChecklistItems && (
            <M_CheckList
              config={props.content}
              data={props.checkListData.checklistData}
              type="preIRC"
              formParam={primaryData}
              {...props}
            />
          )}

          {!props.hideChecklistItems && (
            <Box
              bgcolor={"#fcf8e3"}
              color={"#fec85f"}
              width={"100%"}
              padding={"1rem"}
              marginY={"1rem"}
            >
              {props.content && props.content.ircMssg
                ? props.content.ircMssg
                : labelName.IRC_Mssg}
            </Box>
          )}

          <Box paddingTop={"2rem"} display={"flex"} justifyContent={"end"}>
            <A_Button
              label="Reschedule"
              color={"primary"}
              onClick={handleBookIRC}
            />
            {props?.checkListData?.isCompleted || props.hideChecklistItems ? (
              <O_Mail
                content={props.content}
                label={
                  props.content && props.content.ircMailButtonLabel
                    ? props.content.ircMailButtonLabel
                    : labelName.IRC_BUTTON
                }
                ircID={
                  props.checkListData &&
                  props.checkListData.checklistData &&
                  Array.isArray(props.checkListData.checklistData) &&
                  props.checkListData.checklistData[0] &&
                  props.checkListData.checklistData[0].dealChecklist &&
                  props.checkListData.checklistData[0].dealChecklist.dealIrcId
                }
                formData={{}}
                reload={getReload}
              />
            ) : (
              <A_Button
                label={
                  props.content && props.content.ircMailButtonLabel
                    ? props.content.ircMailButtonLabel
                    : labelName.IRC_BUTTON
                }
                disabled={true}
                color={"primary"}
              />
            )}
          </Box>
          <A_SimpleDialog
            open={bookIRC}
            formTemplateId
            title={ircConfig.RESCHEDULE}
            fullScreen={false}
            height="400px"
            fullwidth={true}
            maxWidth={"md"}
            onClose={handleIRCForm}
            closePopUp={() => {
              handleIRCForm;
            }}
            dialogContent={
              <O_IRCSlots
                onClose={handleIRCForm}
                getReload={getReload}
                modify={true}
                idLabel={
                  props.content &&
                  props.content &&
                  props.content.rescheduleLabel &&
                  props.content.rescheduleLabel.id
                }
                ircConfig={props.content && props.content.rescheduleLabel}
                id={props.id}
              />
            }
          />
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

export default connect(mapStateToProps)(O_CheckList);
