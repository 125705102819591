import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageContext from "../../../../../../contexts/pageContext";
import M_TemplatePageTitle from "../TemplatePageTitle/M_TemplatePageTitle";
import M_PageToolbar from "../Toolbar/M_PageToolbar";
import M_ToolToolbarFilterContainer from "../Toolbar/M_ToolToolbarFilterContainer";
import PropTypes from "prop-types";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useMediaQuery } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: "auto",
    width: "auto",
    elevation: 0,
  },
  secondaryMenuButton: {
    marginLeft: "-19px",
  },
  header: {
    background: "transparent",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "4px !important",
    },
  },
  pageToolbarFIlter: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    flexWrap: "wrap",
    paddingLeft: "16px",
  },
  pageToolbarFIltermobilevisible: {
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "fixed",
    marginLeft: "43%",
    marginTop: "2%",
    visibility: "visible",
  },
  pageToolbarFIltermobilehidden: {
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "fixed",
    marginLeft: "43%",
    marginTop: "2%",
    visibility: "hidden",
  },
  newHeight: {
    height: "96px !important",
  },
  /*   FundDropdown:{
    color:"white"
  } */
}));

const M_TemplatePagesHeader = (props) => {
  const classes = useStyles();
  const headerLogo = props.logo || "company-default.jpg";
  const [heightData, setHeight] = useState(null);
  const { pageUrl, templateId, crmId } = useParams();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { onReload } = props;
  const stageCanvasRef = useRef(null);
  const [InitialBooleanValue, setInitialBooleanValue] = React.useState(false);
  const [currentTemplateId, setCurrentTemplateId] = useState(templateId);
  const toggleBooleanValue = () => {
    InitialBooleanValue
      ? setInitialBooleanValue(false)
      : setInitialBooleanValue(true);
  };

  useEffect(() => {
    if (stageCanvasRef.current) {
      let h = 100 + stageCanvasRef.current.offsetHeight;
      setHeight(h);
      props.callback(h);
    }
    if (templateId !== currentTemplateId) {
      setCurrentTemplateId(templateId);
    }
  }, [
    stageCanvasRef,
    templateId,
    currentTemplateId,
    stageCanvasRef &&
      stageCanvasRef.current &&
      stageCanvasRef.current.offsetHeight,
  ]);
  return (
    <PageContext.Consumer>
      {({ pageConfig }) => {
        let toolbar =
          pageConfig &&
          pageConfig.toolbar !== "" &&
          JSON.parse(pageConfig.toolbar);

        //let toolbar = mockToolbar;
        const optionSets =
          pageConfig && pageConfig.optionSets ? pageConfig.optionSets : [];
        let pageFilters;
        if (toolbar && toolbar.primary && toolbar.primary.filters) {
          const inScopeFilters = toolbar.primary.filters.filter(
            (item) =>
              item.templateIds && item.templateIds.indexOf(templateId) > -1
          );
          if (inScopeFilters && inScopeFilters.length > 0) {
            pageFilters = inScopeFilters;
          } else {
            pageFilters = undefined;
          }
        }

        if (!toolbar && !props.title) {
          return <></>;
        }

        return (
          <>
            <div
              className={`${classes.header} customClass `}
              style={
                !pageFilters
                  ? { paddingBottom: "98px !important" }
                  : { paddingBottom: `${heightData}px !important` }
              }
            >
              <div
                id="M_TemplatePagesHeader1"
                className="header-container"
                style={{ height: "auto" }}
                ref={stageCanvasRef}
              >
                <div
                  id="M_TemplatePagesHeader2"
                  className="grid-item page-title"
                  style={
                    isMobile == false
                      ? {
                          display: "flex",
                          flex: "auto",
                          alignItems: "baseline",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          padding: "8px",
                        }
                      : {}
                  }
                >
                  <M_TemplatePageTitle
                    title={props.title}
                    subtitle={props.subtitle}
                    logo={headerLogo}
                    toolbar={toolbar}
                  ></M_TemplatePageTitle>
                  {isMobile && pageFilters && pageFilters !== undefined ? (
                    <>
                      <MoreVertIcon
                        onClick={function () {
                          toggleBooleanValue();
                        }}
                        style={{ marginLeft: "80%", marginTop: "-7%" }}
                      ></MoreVertIcon>
                      <div
                        className={
                          InitialBooleanValue
                            ? classes.pageToolbarFIltermobilevisible
                            : classes.pageToolbarFIltermobilehidden
                        }
                      >
                        {toolbar && (
                          <M_ToolToolbarFilterContainer
                            key="page_toolbarContainer"
                            toolbar={toolbar}
                            optionSets={optionSets}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className={classes.pageToolbarFIlter}>
                      {toolbar && (
                        <M_ToolToolbarFilterContainer
                          key="page_toolbarContainer"
                          toolbar={toolbar}
                          optionSets={optionSets}
                        />
                      )}
                    </div>
                  )}
                </div>

                <div className="grid-item " style={{ margin: "auto 0" }}>
                  {/*Toolbar starts here */}
                  {toolbar && (
                    <M_PageToolbar
                      key="page_toolbar"
                      title={props.title}
                      toolbar={toolbar}
                      optionSets={optionSets}
                      params={{ pageUrl, crmId, templateId }}
                      downloadExcelTemplatePopUp={
                        props.downloadExcelTemplatePopUp
                      }
                      onReload={onReload}
                      readOnlyTemplate={props.readOnlyTemplate}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        );
      }}
    </PageContext.Consumer>
  );
};
M_TemplatePagesHeader.propTypes = {
  logo: PropTypes.any,
  title: PropTypes.any,
  downloadExcelTemplatePopUp: PropTypes.any,
  subtitle: PropTypes.any,
  onReload: PropTypes.func,
  readOnlyTemplate: PropTypes.any,
  callback: PropTypes.any,
};
export default withRouter(M_TemplatePagesHeader);
