import {getPillar} from "../actions/actionTypes"

let initialState = ""

const getPillarReducer = (state=initialState,action) =>{
    switch(action.type){
        case getPillar.GET_PILLAR:
            
            return action.payload;
        default: 
            return state
    }
}

export default getPillarReducer;