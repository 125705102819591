import { takeLatest, put, call } from "redux-saga/effects";

import { getDataFromSQLSp } from "../../components/Factory/Molecules/Common/File Upload/M_fileUpload.helper";
import {
  fetchApiDataFailure,
  fetchApiDataSuccess,
  fetchApiSelectedDataSuccess,
} from "../actions/proposalTemplateActions";
import { proposalTemplate } from "../actions/actionTypes";

// Fetch API Data for SQL SP
function* fetchAPIDataSaga(action) {
  try {
    const { query, spNamePortfolio, userEmail } = action.payload;
    const response = yield call(getDataFromSQLSp, {
      chartSPs: true,
      spDetails: [
        {
          id: query,
          spName: spNamePortfolio,
          parameters: {
            useremail: userEmail,
          },
        },
      ],
    });
    yield put(fetchApiDataSuccess(response));
  } catch (error) {
    yield put(fetchApiDataFailure(error));
  }
}

// Fetch API Borrower Data for SQL SP
function* fetchAPISelectedValueDataSaga(action) {
  try {
    const { query, spNameBorrower, value, userEmail } = action.payload;
    const response = yield call(getDataFromSQLSp, {
      chartSPs: true,
      spDetails: [
        {
          id: query,
          spName: spNameBorrower,
          parameters: {
            investment: value,
            userEmail: userEmail,
          },
        },
      ],
    });
    yield put(fetchApiSelectedDataSuccess(response));
  } catch (error) {
    yield put(fetchApiDataFailure(error));
  }
}

export function* watchFetchAPIData() {
  yield takeLatest(proposalTemplate.FETCH_API_DATA, fetchAPIDataSaga);
}
export function* watchFetchAPISelectedValueData() {
  yield takeLatest(
    proposalTemplate.FETCH_API_SELECTED_VALUE,
    fetchAPISelectedValueDataSaga
  );
}
