/* eslint-disable */
import React from "react";
import {
  supportedBrowsersList,
  getClientBrowser,
} from "../../../../../Utils/Helpers/common";
import "./ErrorBoundary.scss";
import A_Card from "../../../Atoms/Surfaces/Cards/A_Card";
import A_Link from "../../../Atoms/Navigation/Links/A_Link";
const ErrorSummary = () => {
  return (
    <React.Fragment>
      <span className="error-summary">Unsupported browser</span>
    </React.Fragment>
  );
};
const ErrorDetail = (props) => {
  let errDetail = "";
  let currentBrowser = props.clientBrowser;
  supportedBrowsersList.map((browser) => {
    if (browser.name === currentBrowser.name) {
      currentBrowser.upgradeLink = browser.upgradeLink;
    }
  });

  if (props.browserType === "unsupported") {
    errDetail = (
      <span className="error-detail">
        For best experience, you can try from below browsers.
        {supportedBrowsersList.map((browser) => {
          return (
            <A_Link
              key={browser.name}
              href={browser.upgradeLink}
              text={browser.name}
            />
          );
        })}
      </span>
    );
  }
  if (props.browserType === "older") {
    errDetail = (
      <span className="error-detail">
        For best experience, upgrade your browser.
        <br />
        <br /> You can try from below browsers.
        <A_Link href={currentBrowser.upgradeLink} text={currentBrowser.name} />
        {supportedBrowsersList.map((browser) => {
          if (browser.name !== currentBrowser.name) {
            return (
              <A_Link
                key={browser.name}
                href={browser.upgradeLink}
                text={browser.name}
              />
            );
          }
        })}
      </span>
    );
  }
  return (
    <React.Fragment>
      <b className="error-message">
        {props.browserType === "older"
          ? "Your browers is not updated"
          : "This browser is not supported."}
      </b>
      <br />
      {errDetail}
    </React.Fragment>
  );
};

const M_UnsupportedBrowser = (props) => {
  let clientBrowser = getClientBrowser();
  let browserType = "unsupported";
  if (
    supportedBrowsersList.some((browser) => {
      return (
        browser.name === clientBrowser.name &&
        parseFloat(browser.version) > parseFloat(clientBrowser.version)
      );
    })
  ) {
    browserType = "older";
  }

  return (
    <A_Card
      title={<ErrorSummary />}
      content={
        <ErrorDetail browserType={browserType} clientBrowser={clientBrowser} />
      }
    ></A_Card>
  );
};

export default M_UnsupportedBrowser;
