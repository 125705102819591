/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import TextField from "@material-ui/core/TextField";
import ContactMail from "@material-ui/icons/ContactMail";
import A_SummerNote from "../../../Atoms/Inputs/SummerNote/A_SummerNote";
import "react-summernote/dist/react-summernote.css";
import { httpPost, httpPut } from "../../../../../Utils/apis/apis";
import { connect } from "react-redux";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";
import M_FormDropableFileUpload from "../../../Molecules/Common/File Upload/M_FormDropableFileUpload";
import { handleFileUpload } from "../../../Molecules/Common/File Upload/M_fileUpload.helper";
import A_Autocomplete from "../../../Atoms/Inputs/Autocompletes/A_Autocomplete";
import { Grid } from "@material-ui/core";
import A_MultiSelect from "../../../Atoms/Inputs/Selects/A_MultiSelect";
import { fieldType, ircConfig } from "../../../../../Utils/config/config";
import { emailPrefix } from "../IcEmail/email.helper";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getObjectFromJSONString } from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";
import RawHTMLRenderer from "../../../Atoms/RawHTMLRenderer/A_RawHTMLRenderer";
const useStyles = makeStyles((theme) => ({
  inputLabel: {
    paddingRight: "1.2rem",
    fontWeight: "bold",
  },
  ircButton: {
    color: "#ffffff",
    backgroundColor: "#FFA500",
    textTransform: "none",
  },

  spanError: {
    color: "red",
    margin: "0",
    padding: "0 0 0 40px",
  },
  span: {
    color: "#ff6666",
  },
  btn: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  formGroup: {
    margin: "1rem 0",
  },
  alertMobile: {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginBottom: "30px",
      flexDirection: "column",
      alignItems: "center",
      textAlignLast: "center",
    },
  },
  Button: {
    marginRight: theme.spacing(1),
    marginTop: "6px",
    minWidth: "185px",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "22px",
    marginRight: "15em",
  },
  errorMessage: {
    background: "rgb(253, 237, 237)",
    borderRadius: "4px",
    textAlign: "center",
    color: "rgb(102, 60, 0)",
    marginBottom: "1%",
    marginTop: "-2%",
    display: "flex",
    position: "fixed",
    left: "50%",
    transform: "translate(-50%,0%)",
    zIndex: "1",
  },
}));

function IRC_Mail(props) {
  let { crmId, pageUrl, templateId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [formData, setFormData] = React.useState(props.formData);
  const [remark, setRemark] = React.useState("");
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const user = useSelector((state) => state.oidc.user);
  const classes = useStyles();
  var PersonName = props.user;
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });
  const [options, setOptions] = React.useState();
  const [showWarning, setShowWarning] = React.useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = React.useState(false);
  // function valid(formData) {
  //   if (
  //     formData.subject == null ||
  //     formData.subject == undefined ||
  //     formData.subject == ""
  //   ) {

  //   } else if (
  //     formData.recipients == null ||
  //     formData.recipients == undefined ||
  //     formData.recipients == ""
  //   ) {
  //     setSubjectError("Not empty");
  //   } else if (
  //     formData.remark == null ||
  //     formData.remark == undefined ||
  //     formData.remark == ""
  //   ) {
  //     updateemailRequestError("Not empty");
  //   } else {
  //     setToError("");
  //     setSubjectError("");
  //     updateemailRequestError("");
  //     return true;
  //   }
  // }
  React.useEffect(() => {
    getOptionList();
  }, []);
  const getOptionList = () => {
    const optionList =
      props.currentPage &&
      props.currentPage.pageConfig &&
      props.currentPage.pageConfig.optionSets &&
      Array.isArray(props.currentPage.pageConfig.optionSets) &&
      props.content &&
      props.content.emailOptionSet
        ? props.currentPage.pageConfig.optionSets.filter(
            (optionSet) =>
              optionSet.optionSetName === props.content.emailOptionSet
          )
        : null;
    setOptions(optionList);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleSelect = (selectedValue, id) => {
    let newFormData = { ...formData };
    newFormData[id] = { data: selectedValue, fieldType: fieldType.OPTIONSET };
    setFormData(newFormData);
    setIsButtonDisabled(false);
  };
  const handleClose = () => {
    setShowWarning(true);
    setError("");
  };
  const handleLookup = (selectedValue, id) => {
    let newFormData = formData;
    selectedValue.data != ""
      ? (newFormData[id] = { ...selectedValue, fieldType: "lookup" })
      : (newFormData[id] = undefined);
    setFormData(newFormData);
    setIsButtonDisabled(false);
  };
  function subjectEmail(event) {
    let newFormData = { ...formData };
    newFormData["subject"] = event.target.value;
    setFormData(newFormData);
    setIsButtonDisabled(false);
  }
  function handleSummerNote(e) {
    setRemark(e);
    setIsButtonDisabled(false);
  }
  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.Clipboard;
    const pastedContent = clipboardData.getData("text/plain");
    const processedContent = processPastedContent(pastedContent);
    const newContent = remark + processedContent;
    handleSummerNote(newContent);
  };
  const processPastedContent = (content) => {
    //  you can sanitize, format, or modify the pasted content
    return content;
  };
  const setFileData = (event, files) => {
    event.preventDefault();
    setFiles(files);
    event.preventDefault();
  };
  React.useEffect(() => {
    if (
      (formData.subject !== "" ||
        formData.subject !== null ||
        formData.subject !== undefined) &&
      (formData.recipients !== "" ||
        formData.recipients !== null ||
        formData.recipients !== undefined) &&
      (remark !== "" || remark !== null || remark !== undefined)
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formData]);

  const showResult = async () => {
    setIsButtonDisabled(true);

    const fileName = props.ircID + "/";
    let content = {
      containerType: "email",
      allowedExtensions: ["*"],
    };
    if (
      formData.subject == null ||
      formData.subject == undefined ||
      formData.subject == "" ||
      ((formData.recipients == null ||
        formData.recipients == undefined ||
        formData.recipients == "") &&
        getObjectFromJSONString(
          props,
          ".content.defaultEmail.isRequiredRecipients",
          true
        ) !== false) ||
      remark == null ||
      remark == undefined ||
      remark == ""
    ) {
      setIsEmptyEmail(true);
      setTimeout(() => {
        setIsEmptyEmail(false);
      }, 2000);
    } else {
      setError("");
      let filesData = [];
      for (let item in files) {
        let filePath = emailPrefix + fileName + files[item].name;
        filesData.push(filePath);
      }
      let toUsers = [];
      if (formData && formData.recipients) {
        if (formData.recipients.fieldType === fieldType.LOOKUP) {
          toUsers =
            formData && formData.recipients && formData.recipients.data
              ? formData.recipients.data.map((item) => item.title)
              : [];
        } else if (formData.recipients.fieldType === fieldType.OPTIONSET) {
          toUsers =
            formData && formData.recipients && formData.recipients.data
              ? formData.recipients.data.map((item) => item.value)
              : [];
        }
      }
      let defaultEmail = getObjectFromJSONString(
        props,
        ".content.defaultEmail.defaultEmailList",
        []
      );
      if (Array.isArray(defaultEmail)) toUsers = [...toUsers, ...defaultEmail];
      let ccUsers =
        formData && formData.ccUsers && formData.ccUsers.data
          ? formData.ccUsers.data.map((item) => item.title)
          : [];
      const data = {
        id: "",
        label: "ircemail",
        properties: {
          subject: formData.subject,
          from: ircConfig.EMAIL,
          recipients: JSON.stringify(toUsers),
          remark: remark,
          ccUsers: JSON.stringify(ccUsers),
          attachGUID: props.ircID,
          isMailSent: false,
          responses: "[]",
          attachments: filesData.join(","),
        },
        edges: [],
        security: {
          read: {
            groupids: [],
            users: [],
          },
          update: {
            groupids: [],
            users: [],
          },
          full: {
            groupids: [],
            users: [],
          },
          privateRecord: true,
        },
      };
      let uploadedFile = 0;
      for (let item in files) {
        await handleDocumentUploadAndDataSubmission(
          files[item],
          fileName,
          content,
          uploadedFile,
          files.length,
          data
        );
        uploadedFile += 1;
      }
      if (files.length === 0) {
        await handleMailService(data);
      }
      setIsButtonDisabled(false);
    }
  };
  const handleDocumentUploadAndDataSubmission = async (
    file,
    fileName,
    content,
    uploadedFileCount,
    uploadLength,
    data
  ) => {
    let droppedFiles = [{ files: [file] }];
    await handleFileUpload(
      "",
      crmId,
      user,
      droppedFiles,
      content,
      fileName,
      "",
      ""
    );
    if (uploadedFileCount >= uploadLength - 1) {
      await handleMailService(data);
    }
  };
  const handleMailService = async (payload) => {
    const response = await httpPost(
      `/CRMData/insert?pageUrl=${pageUrl}`,
      payload
    );

    if (response.id != null) {
      var addEdgePayload = {
        sourceVertexId: props.ircID,
        destinationVertexId: response.id,
        edgeLabel: "hasircemail",
      };
      await httpPut(`/CRMData/addEdge`, addEdgePayload);
      httpPut(`/DealChecklist/Complete/${crmId}`).then(() => {
        props.reload ? props.reload() : "";
      });
      setChangesSaved({
        status: true,
        type: "success",
        msg: "Mail Sent Successfully",
      });
    }
    // handleClose();
  };
  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  return (
    <div>
      <A_Button
        onClick={handleClickOpen}
        label={props.label}
        className={classes.ircButton}
        icon={<ContactMail />}
      />
      <A_Snackbar
        open={changesSaved.status}
        message={changesSaved.msg}
        type={changesSaved.type}
        autoHideDuration={2000}
        vertical="bottom"
        horizontal="center"
        handleClose={handleSnackbarClose}
      />
      <A_SimpleDialog
        open={open}
        title="Email"
        height="110"
        maxWidth="sm"
        dialogContent={
          <div>
            {showWarning && (
              <Alert
                severity="warning"
                className={classes.alertMobile}
                action={
                  <div className={classes.buttonWrapper}>
                    <A_Button
                      label="Don't close form"
                      onClick={() => {
                        setShowWarning(false);
                        setError("");
                      }}
                      className={classes.Button}
                      color="primary"
                      variant="outlined"
                    ></A_Button>
                    <A_Button
                      label="Close form anyway"
                      color="secondary"
                      onClick={() => {
                        setShowWarning(false);
                        setOpen(false);
                        setError("");
                        setFormData([]);
                        setFiles([]);
                      }}
                      className={classes.Button}
                      variant="outlined"
                    ></A_Button>
                  </div>
                }
              >
                <AlertTitle>Warning</AlertTitle>
                <strong>
                  Are you sure you want to close this form? You will lose
                  progress so far!
                </strong>
              </Alert>
            )}
            {isEmptyEmail && (
              <Alert severity="error" className={classes.errorMessage}>
                <strong>Please Fill Required Details</strong>
              </Alert>
            )}
            <Grid container alignItems="center">
              <Grid item xs="12">
                <span>
                  <RawHTMLRenderer
                    html={getObjectFromJSONString(
                      props,
                      ".content.defaultEmail.defaultLabel",
                      ""
                    )}
                  />
                </span>
              </Grid>
              <Grid item xs="12">
                {!options ? (
                  <A_Autocomplete
                    fullWidth={true}
                    size="medium"
                    entity={{}}
                    variant="standard"
                    className={classes.inputcontrol}
                    label={`To ${
                      getObjectFromJSONString(
                        props,
                        ".content.defaultEmail.isRequiredRecipients",
                        ""
                      ) === false
                        ? ""
                        : "*"
                    }`}
                    onChange={handleLookup}
                    // {...elementProps}
                    multiple={true}
                    id="recipients"
                    {...props.content.emailList}
                  />
                ) : (
                  <A_MultiSelect
                    defaultValue=""
                    label="To *"
                    fullWidth={true}
                    onChange={handleSelect}
                    id="recipients"
                    multiple={true}
                    options={
                      options && options.length > 0 ? options[0].options : []
                    }
                  />
                )}
              </Grid>
              <Grid item xs="12">
                <A_Autocomplete
                  fullWidth={true}
                  size="medium"
                  entity={{}}
                  variant="standard"
                  className={classes.inputcontrol}
                  label={"CC "}
                  onChange={handleLookup}
                  // {...elementProps}
                  multiple={true}
                  id="ccUsers"
                  {...props.content.emailList}
                />
              </Grid>
              <Grid item xs="12">
                <TextField
                  className={classes.inputField}
                  id="standard-basic"
                  size="large"
                  label="Subject "
                  fullWidth={true}
                  placeholder=""
                  variant="standard"
                  onChange={subjectEmail}
                  value={formData.subject}
                  required
                />
              </Grid>
              <Grid item xs="12" className={classes.formGroup}>
                <A_SummerNote
                  label={"Request *"}
                  height="80"
                  onChange={handleSummerNote}
                  defaultValue={formData.remark}
                  disabled={false}
                  onPaste={handlePaste}
                ></A_SummerNote>
              </Grid>
              <Grid item xs="12" className={classes.formGroup}>
                <M_FormDropableFileUpload
                  files={files}
                  setFiles={setFileData}
                />
              </Grid>
              <div className={`${classes.btn} ${classes.formGroup}`}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                  type="button"
                  onClick={showResult}
                  disabled={isButtonDisabled}
                >
                  <span style={{ textTransform: "none" }}>Send Mail</span>
                </Button>
              </div>
            </Grid>
          </div>
        }
        onClose={handleClose}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    managePage: state.managePage,
    user: state.oidc.user.profile.email,
  };
}
export default connect(mapStateToProps)(IRC_Mail);
