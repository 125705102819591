/* eslint-disable */
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { getHttpConfig } from "../../../../../Utils/Helpers/common";
import "./dropzoneupload.scss";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import CircularDeterminate from "../../../Atoms/Inputs/CircularProgressBar/CircularProgressBar";
import { CloudUpload } from "@material-ui/icons";
import PreviousTemplate from "../Renderers/Views/PreviousTemplate/PreviousTemplate";
import { Divider } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
 reSize:{
 maxWidth:"15rem",
 minWidth:"3rem",
 },
}));
const M_FormDropableFileUpload = (props) => {
  const classes = useStyles();
  const { content } = props;
  const inputFile = useRef(null);
  const [hover, setHover] = useState(false);
  const user = useSelector((state) => state.oidc.user);
  let fileName = "";
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files,setFiles]=useState([])
  const [file, setFile] = useState([]);
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });

  let { pageUrl, crmId } = useParams();

  var inputProps = {
    accept:
      content && content.allowedExtensions
        ? content.allowedExtensions
        : "pdf,pptx,docx,xlsx",
    multiple: content && content.multiple ? content.multiple : false,
    directory: content && content.directory ? content.directory : null,
  };
  useEffect(() => {
    if (props.files) {
      setFiles(props.files);
    }
  }, [props.files]);
  const handleFileUpload = async (e, dropedFiles = null) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  
    let dataFiles = dropedFiles ? dropedFiles : e.target.files;
    let newFiles = [...files];
     
    for (let dataFile of dataFiles) {      
      const fileExists = newFiles.some((file) => file.name === dataFile.name);  
      if (!fileExists) {
        newFiles.push(dataFile);
      }
    } 
    setFiles(newFiles);
    props.setFiles(e, newFiles); 
    e.target.value = "";
    e.preventDefault();
  };
  const downloadPreview = (file) => {
    const url1 = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = url1;
    link.setAttribute("download", file.name);
    document.body.appendChild(link);
    link.click();
  };

  const removeFile = (event, fileIndex) => {
    let newFile = files.filter((item, index) =>  index !== fileIndex)
    setFiles(newFile)
    props.setFiles(event, newFile);
  };


  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  const handleClick = () => {
    inputFile.current.click();
  };

  const handleDropFiles = (event) => {
    stopEvent(event);
    setHover(false);
    const { files } = event.dataTransfer;
    handleFileUpload(event, files);
  };

  const stopEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragOver = (event) => {
    stopEvent(event);
    setHover(true);
  };
  const onDragLeave = (event) => {
    stopEvent(event);
    setHover(false);
  };
  return (
    <div>
      <section style={{ minWidth: "20px" }}>
        <div
          className={
            hover ? "drop-zone-container hover" : "drop-zone-container"
          }
          onDrop={handleDropFiles}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          <CloudUpload color="primary" />
          {!uploading ? (
            <p style={{ color: "#054af7" }}>
              Drag or Drop Files here to upload{" "}
            </p>
          ) : (
            ""
          )}
          {uploading ? <CircularDeterminate /> : ""}
          {!uploading ? (
            <A_Button
              onClick={handleClick}
              onDrop={handleDropFiles}
              onDragLeave={onDragLeave}
              onDragOver={onDragOver}
              color="primary"
              label="Click to Upload"
              disabled={loading}
            />
          ) : (
            ""
          )}
          <input
            {...inputProps}
            className={"fileinput"}
            type="file"
            ref={inputFile}
            multiple={true}
            onChange={handleFileUpload}     
          />
          {loading && <CircularProgress size={20} color="inherit"/>}
        </div>

        <A_Snackbar
          open={changesSaved.status}
          message={changesSaved.msg}
          type={changesSaved.type}
          autoHideDuration={2000}
          horizontal="center"
          handleClose={handleSnackbarClose}
        />
        {/* <div>{file && file.length > 0 ? file : ""}</div> */}
      </section>
      <br />
      {files.map((item, index) => {
        return (
          <div
            className=""
            style={{
              height: "2.6rem",
              margin: "0.2rem",
              display: "inline",
            }}
          >
            <Chip
              label={item.name}
              className={classes.reSize}
              onClick={() => {
                downloadPreview(item);
              }}
              onDelete={(e) => {
                removeFile(e, index);
              }}
            />
          </div>
        );
      })}

      <section>{/* <PreviousTemplate /> */}</section>
    </div>
  );
};

export default M_FormDropableFileUpload;
