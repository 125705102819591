import React from "react";
import PropTypes from "prop-types";
import ClientLogo from "../../../../../../Assets/Images/Defaults/minerva-logo.svg";

const ClientLogoComponent = ({ clientName }) => {
  const getClientLogo = (name) => {
    name = name === "localhost" ? "minerva-logo" : name;
    return `https://stminervaclouddev.blob.core.windows.net/images-store/${name}.png`;
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = ClientLogo;
  };
  return (
    <img
      style={{
        maxHeight: "128px",
        width: "auto",
        maxWidth: "200px",
        display: "flex",
        alignItems: "center",
        margin: "5px auto 5px auto",
      }}
      alt={`${clientName} logo`}
      onError={addDefaultSrc}
      src={getClientLogo(clientName)}
    />
  );
};

ClientLogoComponent.propTypes = {
  addDefaultSrc: PropTypes.func,
  clientName: PropTypes.string,
};
export default React.memo(ClientLogoComponent);
