import { proposalTemplate } from "./actionTypes";

export const fetchApiData = (query, spNamePortfolio, userEmail) => {
  return {
    type: proposalTemplate.FETCH_API_DATA,
    payload: { query, spNamePortfolio, userEmail },
  };
};
export const fetchApiSelectedValue = (
  query,
  spNameBorrower,
  value,
  userEmail
) => {
  return {
    type: proposalTemplate.FETCH_API_SELECTED_VALUE,
    payload: { query, spNameBorrower, value, userEmail },
  };
};
export const fetchApiDataSuccess = (data) => {
  return {
    type: proposalTemplate.FETCH_API_DATA_SUCCESS,
    payload: data,
  };
};
export const fetchApiSelectedDataSuccess = (data) => {
  return {
    type: proposalTemplate.FETCH_API_SELECTED_VALUE_SUCCESS,
    payload: data,
  };
};
export const fetchApiDataFailure = (error) => {
  return {
    type: proposalTemplate.FETCH_API_DATA_FAILURE,
    payload: error,
  };
};
export const fetchFilterData = (portfolio, borrower) => {
  return {
    type: proposalTemplate.FETCH_FILTER_DATA,
    payload: { portfolio, borrower },
  };
};
