/* eslint-disable */

import React from "react";
import { Route, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getCRMContactInfo } from "../Utils/Helpers/common";
import { getRecordByProperty } from "../components/Utils/Oidc/apiService";

function ProtectedRoute({ component: Component, ...restProps }) {
  const isAuthenticated = restProps.user ? true : false;
  const history = useHistory();
  let { crmContactUserId } = getCRMContactInfo();
  const location = {
    pathname: "/",
    state: { referrer: { pathname: history.location.pathname } },
  };
  React.useEffect(() => {
    let email = restProps.user?.profile?.email
    if (
      isAuthenticated &&
      email &&
      !crmContactUserId
    ) {
        let apiPayload = {
          label: "contact",
          properties: {
            email: email,
          },
        };
        getRecordByProperty(apiPayload);
    }
  }, []);
  return (
    <Route
      {...restProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : history.push(location)
      }
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.oidc.user,
  };
}
export default connect(mapStateToProps)(ProtectedRoute);
