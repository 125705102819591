import {getEventClickValue} from "../actions/actionTypes";

let initialState = false;

const getEventValueReducer = (state=initialState, action) =>{
    switch(action.type){
        case getEventClickValue.GET_EVENT_VALUE:
            return action.payload;
            default:
                return state
    }
}
export default getEventValueReducer;