/* eslint-disable */
import { take, put } from "redux-saga/effects";
import { httpPost } from "../../Utils/apis/apis";
import { loggedInUserIdActionTypes } from "../actions/actionTypes";
import { loadState } from "../../Utils/LocalState/localState";
import * as loggedInUserIdActions from "../actions/loggedInUserIdActions";

export function* getLoggedInUserSaga() {
  while (true) {
    const action = yield take(
      loggedInUserIdActionTypes.GET_LOGGED_IN_USER_TYPE
    );

    try {
      let user = null;

      const appState = loadState();
      if (appState) {
        user = appState.oidc.user;
      }
      if (user === null) {
        return {};
      }
      let email = user.profile.email.toLowerCase();
      let convertedFormData = {
        label: "contact",
        properties: {
          email: email,
        },
      };
      let data;
      if (email) {
        const res = yield httpPost(
          `${process.env.API_URL}/CRMData/getRecordByProperty`,
          convertedFormData
        );
        let contact_crm = "";
        if (res && res.length > 0 && res[0] && res[0].id) {
          contact_crm = res[0];
        }
        if (contact_crm) {
          localStorage.setItem("contact_crm", JSON.stringify(contact_crm));
        } else {
          localStorage.setItem("contact_crm", "{}");
        }
        data = res === "Empty" ? [] : res;
        yield put(
          loggedInUserIdActions.getLoggedInUserSucceeded({
            data: data,
            getLoggedInUserStarted: false,
          })
        );
      } else {
        yield put(
          loggedInUserIdActions.getLoggedInUserSucceeded({
            data: [],
            getLoggedInUserStarted: false,
          })
        );
      }
    } catch (e) {
      loggedInUserIdActions.getLoggedInUserFailed({
        msg: "Some error occurred",
        getLoggedInUserStarted: false,
      });
    }
  }
}
