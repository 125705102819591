/* eslint-disable */
import React from "react";
import MinervaLogo from "../../../../../Assets/Images/Defaults/minerva-logo.svg";
import A_LinearIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_LinearIndeternment";
import "./M_RedirectToLogin.scss";
import { log_info } from "../../../../../Utils/logger/logger";
const M_GuestWelcomeCard = (props) => {
  log_info("Redicting to login screen.");
  return (
    <span>
      <img
        className="redirect-login-image"
        src={MinervaLogo}
        alt="Minerva Logo"
        width="100%"
      />
      <A_LinearIndeternment />
    </span>
  );
};

export default M_GuestWelcomeCard;
