/* eslint-disable */
import React from "react";
import defaultAvatar from "../../../../../Assets/Images/Defaults/default_avatar.jpg";
import { getCurrentUser } from "../../../../../Utils/Helpers/common";
import {
  log_info,
  log_warning,
  log_error,
} from "../../../../../Utils/logger/logger";
import A_Avatar from "../../../Atoms/DataDisplay/Avatars/A_Avatar";
const M_UserAvatar = (props) => {
  log_info("Logged-In successfully");
  log_warning("User initials not displayed on first load.");
  log_error("Javascript error occured.");
  let user = getCurrentUser();
  let userInitials = user.userInitials;
  return (
    <React.Fragment>
      <A_Avatar
        size="medium"
        text={userInitials}
        src={props.logoImage ? props.logoImage : defaultAvatar}
        onClick={props.onClick}
      />
    </React.Fragment>
  );
};
// function mapStateToProps(state, ownProps) {
//     let {user}=state.oidc;
//     return {user};
//   }
export default M_UserAvatar;
//export default connect(mapStateToProps)(M_UserAvatar);
