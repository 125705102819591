/* eslint-disable */
import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import { entityRecordActionType } from "../actions/actionTypes";
import * as entityRecordAction from "../actions/entityRecordAction";
import { getHttpConfig } from "../../Utils/Helpers/common";

export function* getEntityRecordSagas() {
  while (true) {
    yield take(entityRecordActionType.GET_ENTITY_RECORD);
    yield select();
    let config = getHttpConfig();

    if (
      !config.headers.Authorization ||
      config.headers.Authorization == "Bearer undefined"
    ) {
      const serializedState = sessionStorage.getItem(
        `oidc.user:${process.env.OIDC_AUTHORITY}:${process.env.CLIENT_ID}`
      );
      if (serializedState === null) {
        return undefined;
      }
      const appState = JSON.parse(serializedState);
      if (appState && appState.access_token) {
        config.headers.Authorization = "Bearer " + appState.access_token;
      }
    }

    try {
      const res = yield axios.get(
        `${process.env.API_URL}/entities/getRecords`,
        config
      );

      yield put(
        entityRecordAction.getEntityRecordSucceeded({
          data: res.data,
          getEntityRecordSucceeded: true,
        })
      );
    } catch (e) {
      yield put(
        entityRecordAction.getEntityRecordFailed({
          msg: "Some error occured",
          getEntityRecordFailed: true,
        })
      );
    }
  }
}
