import { httpPost } from "../../../../../../Utils/apis/apis";
import { coords } from "../../../../../../Utils/config/config";
import { dateConstants } from "../../../../../../Utils/config/config";

const generateHeaders = (
  fileName,
  type,
  fileOperation,
  processFile,
  subdomain,
  additionHeader = {}
) => {
  let header = {
    filename: fileName,
    type: type,
    "file-operation": fileOperation,
    "process-file": processFile,
    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
    subdomain: subdomain,
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(additionHeader || {}),
  };
  let config = {};
  config.headers = {
    ...header,
  };
  return config;
};

export const onDownload = async (
  fileName,
  type,
  modify,
  displayName = null
) => {
  const url = `${process.env.DOCUMENT_HELPER}`;
  let subdomain = new URL(window.location.href).hostname.split(".")[0];

  await httpPost(url, "", {
    ...generateHeaders(fileName, type, "download", "yes", subdomain),
    responseType: "blob",
  })
    .then((response) => {
      if (response) {
        const url1 = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url1;
        let fileDisplayName = fileName
          .split(modify ? "_" : "/")
          .pop()
          .split("/")
          .pop();
        if (displayName) {
          fileDisplayName = displayName;
        }
        link.setAttribute("download", fileDisplayName);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getFileName = async (
  crmId,
  type,
  additionHeader = {},
  payload = {}
) => {
  let url = `${process.env.DOCUMENT_HELPER}`;
  let subdomain = new URL(window.location.href).hostname.split(".")[0];
  if (additionHeader.url) {
    url = additionHeader.url;
  }
  const response = await httpPost(
    url,
    payload,
    generateHeaders(crmId, type, "blob-items", "no", subdomain, additionHeader)
  );
  return response;
};

export const getMapCoords = async (url, payload) => {
  try {
    let resUpdate = await httpPost(url, payload);
    return resUpdate ? resUpdate : coords;
  } catch (error) {
    return coords;
  }
};

export const generateFileName = (row, data) => {
  try {
    let nameParts = [];
    let date = new Date();

    const mappings = {
      "=currentMonth": dateConstants.MONTHS[date.getUTCMonth()],
      "=currentYear": date.getUTCFullYear(),
      "=currentMonth-currentYear": `${
        dateConstants.MONTHS[date.getUTCMonth()]
      }${date.getUTCFullYear()}`,
    };

    data?.downloadfileName?.forEach((item) => {
      if (mappings[item] !== undefined) {
        nameParts.push(mappings[item]);
      } else if (row[item]) {
        nameParts.push(row[item]);
      } else {
        nameParts.push(item);
      }
    });

    let name = nameParts.join(data.delimiter);
    if (!name) {
      name = "invoice";
    }
    name += data.extension ? data.extension : ".pdf";

    return name;
  } catch {
    return "invoice.pdf";
  }
};
