import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const LoaderSkeleton = () => {
  return (
    <>
      <Skeleton variant="text" width={80} height={80} animation="wave" />
      <Skeleton variant="text" width="100%" height={80} animation="wave" />
      <Skeleton variant="rect" width="100%" height={118} animation="wave" />
    </>
  );
};

export default LoaderSkeleton;
