/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import LoginPage from "./loginPage";
import MainPage from "./mainPage";
function HomePage(props) {
  console.log("HomePage props");
  console.log(props);
  const { user } = props;
  return !user ? <LoginPage {...props} /> : <MainPage />;
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
