import { setShowSeachActionTypes } from "../actions/actionTypes";

let initialState = false;
const showSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case setShowSeachActionTypes.SET_SHOW_SEARCH:
      return action.payload;
    default:
      return state;
  }
};
export default showSearchReducer;
