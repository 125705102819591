import React from "react";
import PropTypes from "prop-types";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
//www.npmjs.com/package/react-summernote
import "bootstrap/js/dist/modal";
import * as injectJavascript from "./summernoteHelper";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import A_FormHelper from "../../DataDisplay/Tooltips/A_FormHelper";
import { getStateFromLocalStorage } from "../../../Molecules/Common/Renderers/M_FormRendererHelper";

const useStyles = makeStyles(() => ({
  labelSummerNote: {
    padding: 0,
    fontSize: "1rem",
    fontFamily: "inherit",
    fontWeight: 400,
    lineHeight: 1,
    marginTop: "16px",
    marginBottom: "10px",
  },
  reactSummernote: {
    color: "black !important",
    "& p": { marginBottom: 0, color: "black !important" },
    "& .note-btn.dropdown-toggle::after": {
      display: "none",
      color: "black !important",
    },
  },
}));

const setStateToLocalStorage = (data) => {
  localStorage.setItem("formData", data);
};
function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

const A_SummerNote = (props) => {
  let currentFieldId = props.id;
  let currentvalue = props.value ? props.value : "";
  if (!currentvalue && props.focus) {
    setStateToLocalStorage(props.formData);
  } else if (localStorage.getItem("formData")) {
    let data = getStateFromLocalStorage();
    let currentLength = stripHtml(currentvalue).length;
    let existingLength = stripHtml(data[currentFieldId]).length;
    if (existingLength + 1 != currentLength) {
      setStateToLocalStorage(props.formData);
    }
  } else {
    setStateToLocalStorage(props.formData);
  }
  const inputFile = React.useRef(null);
  const classes = useStyles();
  let keyIndicator = "";
  let validateKeypress = false;
  let styledRemoved =
    (props && props.removeStyle == false) ||
    (props &&
      props.removeStyle &&
      typeof props.removeStyle == "string" &&
      props.removeStyle.toLowerCase() == "false")
      ? false
      : true;

  const [alertWarning, setalertWarning] = React.useState(
    `This field has a Character limit : ${
      props && props.inputMaxLength && Number(props.inputMaxLength)
    } , Exceeding characters will be trimmed`
  );
  function onBlurHandler() {
    localStorage.removeItem("pasted");
    props &&
      props.inputMaxLength &&
      Number(keyIndicator) === Number(props.inputMaxLength) &&
      onChangeHandler("", props);
  }

  // Additional keys to support
  const allowedKeys = [
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Backspace",
  ];
  function handleKeyPress(ev) {
    keyIndicator = keyIndicator <= 0 ? 0 : keyIndicator;

    // Handle backspace
    if (Number(keyIndicator) > 0 && ev.key === "Backspace") {
      ev.returnValue = true;
    }

    // Handle max length
    if (props && Number(props.inputMaxLength) === 0) {
      ev.preventDefault();
    }

    // Detect current selection length
    const selection = window.getSelection();
    const selectedText = selection.toString();
    const selectedTextLength = selectedText.length;

    // Handle character limit with selection in mind
    if (props && props.inputMaxLength) {
      const outerValue = ev.currentTarget.innerHTML.replace(
        /(<([^>]+)>)/gi,
        ""
      );
      const remainingLength = Math.max(
        Number(props.inputMaxLength) - (outerValue.length - selectedTextLength),
        0
      );

      if (remainingLength === 0 && !allowedKeys.includes(ev.key)) {
        ev.preventDefault();
      }
    }
  }

  React.useEffect(() => {
    if (props && props.inputMaxLength) {
      onChangeHandler(
        Number(props.inputMaxLength) && props.defaultValue != ""
          ? props.defaultValue
          : "",
        props
      );
    }
  }, [props.id]);

  function extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  const isWordDocument = (pastedData) => {
    return pastedData.includes(
      'xmlns:w="urn:schemas-microsoft-com:office:word"'
    );
  };

  const handlePaste = (e) => {
    e.preventDefault();
    // Get the pasted plain text and HTML data
    const plainTextData = e.originalEvent.clipboardData.getData("text/plain");
    let htmlTextsData = e.originalEvent.clipboardData.getData("text/html");
    const isWordDoc = isWordDocument(htmlTextsData);

    // Get current selected text length in the editor
    const selection = window.getSelection();
    const selectedText = selection.toString();
    const selectedTextLength = selectedText.length;

    // Clean or limit the new value if it's an HTML or plain text paste
    let newValue = htmlTextsData
      ? styledRemoved
        ? injectJavascript.summernoteTagRemover(htmlTextsData)
        : htmlTextsData
      : plainTextData.replace(/\n/g, "<br>");

    // Calculate the total content length and remaining space based on the current selection
    let outerValue = e.currentTarget.innerHTML;
    outerValue = extractContent(outerValue);
    const outerLength = outerValue.length;
    const remainingLength = Math.max(
      Number(props.inputMaxLength) - (outerLength - selectedTextLength), // Subtract selected text length
      0
    );

    // Limit the new pasted content based on the remaining allowed characters
    if (props.inputMaxLength) {
      if (remainingLength && htmlTextsData) {
        newValue = injectJavascript.limitHtmlStringWithTags(
          newValue,
          remainingLength,
          isWordDoc
        );
      } else {
        newValue = newValue.substring(0, remainingLength);
      }
    }

    // Insert the pasted content into the current selection
    const range = selection.getRangeAt(0);
    range.deleteContents();
    const pastedNode = document.createElement("div");
    pastedNode.innerHTML = newValue;
    range.insertNode(pastedNode);

    // Adjust the selection to after the inserted content
    const newRange = document.createRange();
    newRange.setStartAfter(pastedNode);
    newRange.collapse(true);

    // Remove existing selection and add the new range
    selection.removeAllRanges();
    selection.addRange(newRange);
  };

  const onChangeHandler = (value, props) => {
    let textContent = stripHtml(value);
    if (value === "<p><br></p>" || textContent.trim() === "") {
      value = "";
      textContent = "";
    }
    let hexVal = props.inputMaxLength
      ? injectJavascript.rgbtoHex(value)
      : value;
    let regexValueRemove = props.inputMaxLength
      ? extractContent(hexVal)
      : value;
    props &&
      props.inputMaxLength &&
      regexValueRemove.length === 0 &&
      setalertWarning(
        `You have ${props.inputMaxLength} characters remaining , Maximum character limit : ${props.inputMaxLength}`
      );
    regexValueRemove =
      props &&
      props.inputMaxLength &&
      regexValueRemove &&
      regexValueRemove.replace(
        /&[a-zA-Z]+;|&[a-zA-Z]+;/g,
        (match) =>
          ({
            "&lt;": "<",
            "&gt;": ">",
            "&quot;": '"',
            "&apos;": "'",
            "&nbsp;": " ",
            "&amp;": "&",
            "&copy;": "©",
            "&reg;": "®",
            "&trade;": "™",
            "&euro;": "€",
          }[match])
      );

    const textlength =
      props &&
      props.inputMaxLength &&
      regexValueRemove &&
      regexValueRemove.length;
    const inputVal = props && props.inputMaxLength && regexValueRemove;

    const valChange =
      props && props.inputMaxLength && Number(props.inputMaxLength);
    const providerVal =
      props && props.inputMaxLength && inputVal && inputVal.includes("&nbsp")
        ? props &&
          props.inputMaxLength &&
          inputVal.split("&nbsp;").join(" ").slice(0, valChange)
        : props && props.inputMaxLength && inputVal.slice(0, valChange);
    const num =
      props &&
      props.inputMaxLength &&
      inputVal &&
      inputVal.split("&nbsp;").join(" ");
    const differenceVal = props && props.inputMaxLength && num;

    props &&
      props.inputMaxLength &&
      valChange &&
      textlength &&
      typeof textlength != "string" &&
      differenceVal &&
      differenceVal.length > 0 &&
      setalertWarning(
        `You have ${
          valChange - differenceVal.length >= 0
            ? valChange - differenceVal.length
            : "no"
        } characters remaining , Maximum character limit : ${valChange}`
      );
    props &&
      props.inputMaxLength &&
      valChange &&
      textlength &&
      typeof textlength != "string" &&
      differenceVal &&
      differenceVal.length == 0 &&
      setalertWarning(
        `You have ${valChange} characters remaining , Maximum character limit : ${valChange}`
      );
    if (
      props &&
      props.inputMaxLength &&
      valChange &&
      textlength &&
      typeof textlength != "string" &&
      differenceVal
    ) {
      if (differenceVal && differenceVal.length > 0) {
        keyIndicator = valChange - differenceVal.length;
      } else if (differenceVal && differenceVal.length == 0) {
        keyIndicator = valChange;
      }
    }
    props &&
      props.inputMaxLength &&
      typeof textlength === "string" &&
      setalertWarning(
        `You have ${valChange} characters remaining , Maximum character limit : ${valChange}`
      );
    if (props && props.inputMaxLength) {
      let dataMax = "";
      if (inputVal.length > props.inputMaxLength) {
        dataMax = injectJavascript.limitHtmlStringWithTags(
          hexVal,
          inputVal.length,
          false
        );
      } else {
        dataMax = hexVal;
      }

      dataMax = dataMax ? injectJavascript.rgbtoHex(dataMax) : dataMax;
      dataMax =
        dataMax && styledRemoved
          ? injectJavascript.cleanHTML(dataMax)
          : dataMax;
      dataMax = injectJavascript.removeEmptyTags(dataMax);
      props.onChange(
        props &&
          props.inputMaxLength &&
          inputVal &&
          providerVal &&
          validateKeypress === false &&
          dataMax,
        props.id,
        { ...getStateFromLocalStorage() }
      );
    } else {
      value = value ? injectJavascript.rgbtoHex(value) : value;
      value = injectJavascript.removeEmptyTags(value);
      let CleanHtml =
        value && styledRemoved ? injectJavascript.cleanHTML(value) : value;
      props.onChange(CleanHtml, props.id, { ...getStateFromLocalStorage() });
    }
  };

  return (
    <>
      <label className={classes.labelSummerNote}>
        {props.required ? props.label + "*" : props.label}
      </label>
      <span>
        <A_FormHelper fieldHelperText={props.fieldHelperText} />
      </span>
      <ReactSummernote
        disabled={props.disabled}
        tag="textarea"
        className={classes.reactSummernote}
        options={{
          height: props.height,
          dialogsInBody: true,
          dragAndDrop: false,
          disableDragAndDrop: true,
          dialogsFade: true,
          toolbar: [
            ["style", ["bold", "italic", "underline", "clear"]],
            ["color", ["color", "reset"]],
            [
              "image",
              [
                "image",
                "insert",
                "dragImageHere",
                "dropImage",
                "selectFromFiles",
                "url",
              ],
            ],
            ["para", ["ul", "ol", "paragraph", "listStyles"]],
            ["history", ["undo", "redo"]],
            ["table", ["table"]],
          ],
        }}
        ref={inputFile}
        onPaste={(e) => handlePaste(e)}
        onBlur={(e) => onBlurHandler(e, props)}
        onChange={(e) => onChangeHandler(e, props)}
        onKeyDown={(ev) => {
          ev.stopPropagation(), handleKeyPress(ev);
        }}
      >
        {props.defaultValue}
      </ReactSummernote>
      {props && props.inputMaxLength && (
        <p style={{ fontSize: "0.75rem" }}>{alertWarning}</p>
      )}
    </>
  );
};
A_SummerNote.defaultProps = {
  label: "SummerNote",
};
A_SummerNote.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  height: PropTypes.string,
  inputMaxLength: PropTypes.any,
  id: PropTypes.any,
  required: PropTypes.any,
  fieldHelperText: PropTypes.string,
  removeStyle: PropTypes.any,
  formData: PropTypes.any,
  value: PropTypes.any,
  focus: PropTypes.bool,
};
export default A_SummerNote;
