import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store";
import App from "./components/App";
import userManager from "./Utils/Oidc/userManager";
import "./Assets/css/main.scss";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

createRoot(document.getElementById("app")).render(
  <BrowserRouter>
    <Provider store={store}>
      <OidcProvider userManager={userManager} store={store}>
        <I18nextProvider i18n={i18n} useSuspense={false}>
          <App />
        </I18nextProvider>
      </OidcProvider>
    </Provider>
  </BrowserRouter>
);
