import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../../store/actions/currentPageActions";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router";
import {
  getBreadCrumpsV2,
  routeBack,
  setBreadCrumpsV2,
} from "./M_BreadCrumbs.helper";
const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("md")]: {
      marginTop: "-0.4em",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "3em",
    },
  },
  titleContent: {
    marginLeft: "12px",
  },
  preContent: {
    "&:hover": {
      color: "#75BBF8",
    },
  },
  title: {
    fontSize: "1.25rem",
    textTransform: "capitalize",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  titleSection: {
    padding: "10px",
  },
  seperator: {
    fontSize: "20px",
    marginBottom: "2px",
  },
  subtitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    fontSize: "15px",
  },
  logo: {
    marginLeft: "6px",
  },
  folderlink: {
    paddingTop: "10px",
    alignItem: "center",
  },
}));

const M_TemplatePageTitle = (props) => {
  const classes = useStyles();
  const { crmId, pageUrl, templateId } = useParams();
  const history = useHistory();
  const [previousDetails, setPreviousDetails] = React.useState();
  React.useEffect(() => {
    setBreadCrumpsV2({
      pageUrl,
      crmId,
      templateId,
      title: props.title || props.toolbar.PageTitle,
    });
    setPreviousDetails(getBreadCrumpsV2());
  }, [pageUrl, crmId, templateId]);
  return (
    <div id="PageTitleWrapper" className={classes.titleWrapper}>
      {/* Commented due to moving icon from Template to card headers */}
      {/* 
      
      <div className={classes.logo}>
        <A_Avatar
          alt="Logo"
          text={props.title? props.title[0]: ""}
          src={logoImage}
          size={"medium"}
        />
      </div> 
      
      */}

      <div className={classes.titleContent}>
        {previousDetails &&
        previousDetails.title &&
        previousDetails.title !== "" ? (
          <>
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                routeBack();
                history.push(
                  `/${previousDetails.pageUrl}/${previousDetails.crmId}/${previousDetails.templateId}`
                );
              }}
              className={`${classes.title} ${classes.preContent}`}
            >
              {previousDetails.title}
            </span>
            <ChevronRightIcon className={classes.seperator} />
          </>
        ) : (
          ""
        )}
        <>
          <span
            className={classes.title}
            style={{ textTransform: "capitalize" }}
          >
            {props.title
              ? props.title
              : props.toolbar && props.toolbar.PageTitle
              ? props.toolbar && props.toolbar.PageTitle
              : previousDetails &&
                previousDetails.title &&
                previousDetails.title !== ""
              ? pageUrl
              : ""}
          </span>
        </>
        {props.subtitle && (
          <>
            <ChevronRightIcon className={classes.seperator} />
            <span className={classes.subtitle}>{props.subtitle}</span>
          </>
        )}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}
M_TemplatePageTitle.propTypes = {
  toolbar: PropTypes.object,
  optionSets: PropTypes.array,
  currentPage: PropTypes.object,
  primary: PropTypes.object,
  logo: PropTypes.any,
  title: PropTypes.any,
  subtitle: PropTypes.any,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(M_TemplatePageTitle);
