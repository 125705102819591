import React from "react";
import {
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Grid,
} from "@material-ui/core";
import A_TextField from "../../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../../Atoms/Inputs/Selects/A_Select";
import A_IconButton from "../../../../Atoms/Inputs/Buttons/A_IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { esgMainQuestionnaireCss } from "../Helper/Esg.css";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";

const useStyles = makeStyles(esgMainQuestionnaireCss);
const AnswerType = [
  {
    value: "options",
    label: "options",
  },
  {
    value: "number",
    label: "number",
  },
  {
    value: "text",
    label: "text",
  },
];

export default function MainQuestionnaire(props) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);
  const [showTextLimit, setShowTextLimit] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState( props && props.questionStructure && props.questionStructure.answerType && props.questionStructure.answerType == "options" ? true :false);
  const quesStructure = {
    title: "",
    questionWeight: 4, //scale
    answerType: "",
    textLimit: 0,
    answerOptions: [],
  };
  const handleAddQuestionnaire = () => {
    props.handlequestions(props.sectionId, null, quesStructure);
  };
  const handleDeleteQuestionnaire = (event, fileIndex) => {
    props.handlequestions(props.sectionId, props.quesId, quesStructure, true);
  };

  const handleQuestionUpdate = (index, val) => {
    let newQuestionStructure = { ...props.questionStructure };
    newQuestionStructure.title = val;
    props.handlequestions(props.sectionId, props.quesId, newQuestionStructure);
  };
  const handleDropdowns = (index, val) => {
    let newQuestionStructure = { ...props.questionStructure };
    newQuestionStructure.answerType = val;
    let value = val;
    if (value == "options") {
      setShowDialog(true);
      newQuestionStructure.answerOptions = [""];
    }
    if (value == "text") {
      setShowTextLimit(true);
      setShowOptions(false)
    }
    props.handlequestions(
      props.sectionId,
      props.quesId,
      newQuestionStructure,
      false,
      true
    );
  };
  const handleMultipleOptions = (e, id) => {
    let newQuestionStructure = { ...props.questionStructure };
    newQuestionStructure.answerOptions[id] = e.target.value;
    setShowOptions(true);
    setShowTextLimit(false);
    props.handlequestions(props.sectionId, props.quesId, newQuestionStructure);
  };
  const handleOptionsView = () => {
    setShowDialog(true);
  };
  const handleAddOption = (e) => {
    let newQuestionStructure = { ...props.questionStructure };
    newQuestionStructure.answerOptions.push([]);
    props.handlequestions(props.sectionId, props.quesId, newQuestionStructure);
  };
  const handleTextLimit = (val) => {
    let newQuestionStructure = { ...props.questionStructure };
    newQuestionStructure.textLimit = parseInt(val);
    props.handlequestions(props.sectionId, props.quesId, newQuestionStructure);
  };
  const handleScale = (val) => {
    let newQuestionStructure = { ...props.questionStructure };
    newQuestionStructure.questionWeight = parseInt(val);
    props.handlequestions(props.sectionId, props.quesId, newQuestionStructure);
  };
  let handlePopup = () => {
    let data = !showDialog;
    setShowDialog(data);
  };

  const handleOptions = (index) => {
    return (
      <div>
        {props.questionStructure.answerOptions.map((item, id) => (
          <div>
            <TextField
              label=" "
              className={classes.textField1}
              defaultValue={item}
              onChange={(e) => handleMultipleOptions(e, id)}
            />
            <A_IconButton
              color="inherit"
              size="medium"
              icon={<AddCircleOutlineIcon  className={classes.add} />}
              className={classes.btnshow}
              onClick={handleAddOption}
            ></A_IconButton>
          </div>
        ))}
        <A_Button
          className={classes.saveButtonCss}
          label="Save"
          color="primary"
          onClick={handlePopup}
          disabled={props.disabled}
        />
      </div>
    );
  };

  const QuestionnaireStructure = (index) => {
    return (
      <TableRow key={props.key}>
        <TableCell align="left" component="th" scope="row">
          <TextField
            id={props.key}
            label=" "
            variant="standard"
            fullWidth="true"
            className={classes.textField}
            defaultValue={props.questionStructure.title}
            value={props.questionStructure.title}
            onChange={(e) => handleQuestionUpdate(index, e.target.value)}
          />
        </TableCell>
        <TableCell align="left" width="200px">
          <div className="">
            <Grid container alignItems="center">
              <Grid item xs={showTextLimit || showOptions ? "8" : "12"}>
                <A_Select
                 label=" "
                  key={props.id}
                  native={true}
                  variant="standard"
                  fullWidth={true}
                  className={classes.inputcontrolDropdown}
                  defaultValue={props.questionStructure.answerType}
                  options={AnswerType}
                  onChange={(e) => handleDropdowns(index, e.value)}
                />
              </Grid>
              {showOptions ? (
                <Grid item xs="4"  className={classes.grid}>
                  <Tooltip title="View Options">
                    <IconButton
                      onClick={handleOptionsView}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : showTextLimit ? (
                <Grid item xs="4" className={classes.grid}>
                  <TextField
                    id={props.key}
                    variant="standard"
                    label=" "
                    type={"number"}
                    defaultValue="100"
                    onChange={(e) => handleTextLimit(index, e.target.value)}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </div>
        </TableCell>

        <TableCell width="90px" align="left">
          <A_TextField
            id={props.key}
            variant="standard"
            label=" "
            type={"number"}
            defaultValue="5"
            onChange={(e) => handleScale(index, e.target.value)}
          />
        </TableCell>
        <TableCell width="50px">
          <A_IconButton
            color="inherit"
            size="medium"
            variant="contained"
            icon={<AddCircleOutlineIcon />}
            onClick={handleAddQuestionnaire}
          ></A_IconButton>
        </TableCell>
        <TableCell width="50px">
          <A_IconButton
            color="inherit"
            size="medium"
            index={index}
            variant="contained"
            icon={<DeleteIcon />}
            onClick={(e) => handleDeleteQuestionnaire(e, index)}
          ></A_IconButton>
        </TableCell>
        {showDialog == true ? (
          <A_SimpleDialog
            open={showDialog}
            className={classes.dialogBoxHeight}
            title="Please enter options"
            fullScreen={false}
            maxWidth={"xs"}
            onClose={handlePopup}
            closePopUp={handlePopup}
            dialogContent={handleOptions()}
          />
        ) : (
          ""
        )}
      </TableRow>
    );
  };
  return (
    <>{props.questionStructure ? QuestionnaireStructure(props.quesId) : ""}</>
  );
}
