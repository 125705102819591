/* eslint-disable */
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Theme from "../../../../Themes/defaultTheme";
//import Default_Avatar from "../../../../../Assets/Images/Defaults/default_avatar.jpg";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const A_Avatar = (props) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={Theme}>
      <div className={classes.root}>
        {props.size === "small" && (
          <Avatar alt={props.alt} src={props.src} className={classes.small}>
            {" "}
            {props.text}
          </Avatar>
        )}
        {props.size === "medium" && (
          <Avatar alt={props.alt} src={props.src} className={classes.medium}>
            {" "}
            {props.text}
          </Avatar>
        )}
        {props.size === "large" && (
          <Avatar alt={props.alt} src={props.src} className={classes.large}>
            {" "}
            {props.text}
          </Avatar>
        )}
        {props.size === "normal" && (
          <Avatar alt={props.alt} src={props.src}>
            {" "}
            {props.text}
          </Avatar>
        )}
      </div>
    </ThemeProvider>
  );
};
A_Avatar.defaultProps = {
  alt: "Alt text",
  text: "UN",
  src: null,
  size: "large",
  variant: "rounded",
};
export default A_Avatar;
