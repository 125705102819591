import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

function LoadingLayout() {
  return (
    <div className="skelton-wrapper">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={60}
            animation="wave"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={500}
            animation="wave"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={500}
            animation="wave"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={500}
            animation="wave"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={500}
            animation="wave"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default LoadingLayout;
