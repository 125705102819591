import { Skeleton } from "@material-ui/lab";
import React from "react";
import { getFileName } from "../../../Atoms/DataDisplay/DataGrid/helper/download.helper";
import selectedIcon from "../../../Molecules/Common/Renderers/Views/PreviousTemplate/Icon.helpers";
import { connect } from "react-redux";
import * as previousUploadActions from "../../../../../store/actions/previousUploadActions";
import { bindActionCreators } from "redux";
import { useParams } from "react-router";
import {
  downloadDocument,
  onDeleteDocument,
} from "../../../Molecules/Common/Renderers/Views/PreviousTemplate/previousUpload.helper";
import {
  Grid,
  List,
  makeStyles,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import M_ActionGroup from "../../../Molecules/Common/ActionGroup/M_ActionGroup";
import {
  epochToDateFormatHelper,
  toEpochFormat,
} from "../../../../../Utils/Helpers/dateUtils";
import propTypes from "prop-types";
import { executeJSString } from "../../../Pages/Common/Layouts/layout.Helper";
import { getObjectFromJSONString } from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";
import { message } from "../../../../../Utils/config/messages";

const useStyles = makeStyles(() => ({
  listView: {
    overflowWrap: "anywhere",
    marginTop: "1rem",
    maxHeight: "50vh",
    overflow: "auto",
  },
  CustomCard: {
    maxHeight: "52vh",
    overflow: "scroll",
    overflowX: "hidden",
  },
  list: {
    borderBottom: "1px solid lightgrey",
    "&:hover": {
      backgroundColor: "#f9f9f9",
      cursor: "pointer",
    },
  },
  avatar: {
    margin: "auto",
    paddingLeft: "10px",
    width: "10%",
  },
  heading: {
    marginTop: "10px",
  },
  ActionGroup: {
    padding: "1rem 0",
  },
  ListFlex: {
    display: "flex",
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));
function O_HistoricalDocumentUpload(props) {
  const [fileList, setFileList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { crmId, pageUrl } = useParams();
  const [disabledItems, setDisabledItems] = React.useState([]);
  const classes = useStyles();
  const scrollDisable =
    props && props.content && props.content.scrollDisable ? false : true;
  const getDocument = async () => {
    let fileName =
      crmId == 0
        ? // eslint-disable-next-line react/prop-types
          props.content && props.content.fileName
          ? props.content.fileName
          : pageUrl
        : crmId;
    fileName =
      props.content && props.content.directory
        ? props.content.directory
        : fileName;
    let type =
      props.content && props.content.containerType
        ? props.content.containerType
        : "generic";
    let pageLevelFilter = {};
    if (getObjectFromJSONString(props, ".content.usePageLevelFilter")) {
      props.filters &&
        Array.isArray(props.filters) &&
        props.filters.forEach((item) => {
          pageLevelFilter[item.filterName] = item.value;
        });
    }

    let data = await getFileName(
      fileName,
      type,
      getObjectFromJSONString(props, ".content.additionHeaders"),
      pageLevelFilter
    );
    setFileList(data);
    setIsLoading(false);
  };
  const handleDownload = async (
    item,
    index,
    title,
    customFileName = undefined
  ) => {
    // Disable the specific item
    setDisabledItems((prevDisabledItems) => {
      const updatedDisabledItems = [...prevDisabledItems];
      updatedDisabledItems[index] = true;
      return updatedDisabledItems;
    });
    const { additionDownloadHelper } = props.content;
    let container =
      props.content && props.content.containerType
        ? props.content.containerType
        : "generic";
    let fileData = {
      file: item.file,
      fileDisplayName: customFileName ? title + ".pdf" : title,
    };
    await downloadDocument(
      fileData,
      container,
      additionDownloadHelper ? additionDownloadHelper : ""
    );
    //Enabling specific item
    setDisabledItems((prevDisabledItems) => {
      const updatedDisabledItems = [...prevDisabledItems];
      updatedDisabledItems[index] = false;
      return updatedDisabledItems;
    });
  };
  const handleDelete = async (item) => {
    let container =
      props.content && props.content.containerType
        ? props.content.containerType
        : "generic";
    await onDeleteDocument(item, container);
  };
  React.useEffect(() => {
    try {
      getDocument();
      // eslint-disable-next-line no-empty
    } catch (e) {}
    //TODO  Loading is not coming while API is pending so commented this for now
    // setIsLoading(false);
  }, [props.previousUpload, props.filters]);

  function formatString(template, rowData, dateFormat = "MM yyyy") {
    let dataObject = { ...props.currentPage.data, ...rowData };
    const formatDate = (date) =>
      epochToDateFormatHelper(toEpochFormat(new Date(date), true), dateFormat);

    return template.replace(/{(.*?)}/g, (match, key) => {
      key = key.trim();

      if (/time|date/i.test(key)) {
        return formatDate(dataObject[key]);
      }

      return dataObject[key] !== undefined ? dataObject[key] : match;
    });
  }

  return (
    <div className={scrollDisable ? classes.CustomCard : ""}>
      {isLoading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <div>
          {Array.isArray(fileList) && fileList.length > 0 ? (
            fileList.map((item, index) => {
              const hasFormatAndDate =
                props.content?.customFileName?.format &&
                props.content?.customFileName?.dateFormat;

              const tooltipTitle = hasFormatAndDate
                ? formatString(
                    props.content.customFileName.format,
                    item,
                    props.content.customFileName.dateFormat
                  )
                : item.file.split("/").pop();

              return (
                <ListItem key={index} className={classes.list}>
                  <Grid container spacing={2}>
                    <Grid item xs={11}>
                      <List
                        button
                        onClick={() =>
                          handleDownload(
                            item,
                            index,
                            tooltipTitle,
                            hasFormatAndDate
                          )
                        }
                        className={`${classes.ListFlex} ${
                          disabledItems[index] ? classes.disabled : ""
                        }`}
                      >
                        <Grid alignItems="center" container spacing={2}>
                          <Grid item xs={2}>
                            <ListItemAvatar className={classes.avatar}>
                              <Avatar
                                src={selectedIcon(item.file)}
                                variant="square"
                              />
                            </ListItemAvatar>
                          </Grid>
                          <Grid item xs={10}>
                            <ListItem alignItems="flex-start">
                              <ListItemText
                                primary={
                                  <div
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width: "100%",
                                    }}
                                  >
                                    <Tooltip
                                      title={tooltipTitle}
                                      placement="bottom"
                                    >
                                      <span>{tooltipTitle}</span>
                                    </Tooltip>
                                  </div>
                                }
                                secondary={
                                  item.modifiedTime || item.creationTime
                                    ? `Uploaded on ${epochToDateFormatHelper(
                                        item.modifiedTime || item.creationTime,
                                        getObjectFromJSONString(
                                          props,
                                          ".content.dateFormat"
                                        ) || "DD-MM-YYYY"
                                      )}`
                                    : ""
                                }
                              />
                            </ListItem>
                          </Grid>
                        </Grid>
                      </List>
                    </Grid>
                    {props.currentPage?.pageConfig?.pageSecurity?.Full ===
                      true &&
                    executeJSString(
                      getObjectFromJSONString(
                        props,
                        ".content.readOnlyTemplate"
                      ),
                      getObjectFromJSONString(props, ".currentPage.data")
                    ) !== true ? (
                      <Grid item xs={1} className={classes.ActionGroup}>
                        <div>
                          <M_ActionGroup
                            delete={props.content.delete}
                            onDelete={() => handleDelete(item)}
                            modify={true}
                          />
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                </ListItem>
              );
            })
          ) : (
            <p style={{ textAlign: "center" }}>{message.NO_CONTENT}</p>
          )}
        </div>
      )}
    </div>
  );
}
O_HistoricalDocumentUpload.propTypes = {
  content: propTypes.any,
  currentPage: propTypes.any,
  filters: propTypes.any,
  previousUpload: propTypes.any,
};
function mapStateToProps(state) {
  return {
    previousUpload: state.previousUpload,
    currentPage: state.currentPage,
    filters: state.manageToolbarSelections.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, previousUploadActions), dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(O_HistoricalDocumentUpload);
