/* eslint-disable */
import { take, put } from "redux-saga/effects";
import { httpPost } from "../../Utils/apis/apis";
import { dealTeamActionType } from "../actions/actionTypes";
import * as dealTeamActions from "../actions/dealTeamAction";
import { setToast } from "../actions/toastActions";
import { toastType, toastSeverity } from "../../Utils/config/toastConfig";
import { message } from "../../Utils/config/messages";
import { getHttpConfig } from "../../Utils/Helpers/common";
export function* getEdgesSagas() {
  while (true) {
    const action = yield take(dealTeamActionType.GET_DEAL_TEAM_EDGE);
    const payload = action.payload;
    try {
      const res = yield httpPost(
        `${process.env.API_URL}/CRMData/getEdge`,
        payload
      );
      yield put(
        dealTeamActions.getEdgeSucceeded({
          data: res,
          loading: false, // return loading false when data is available
        })
      );
    } catch (e) {
      yield put(
        dealTeamActions.getEdgeFailed({
          msg: "Some error occured",
          getEdgeFailed: true,
        })
      );
    }
  }
}

export function* delEdgeSagas() {
  while (true) {
    const action = yield take(dealTeamActionType.DEL_DEAL_TEAM_EDGE);
    let config = getHttpConfig();

    if (
      !config.headers.Authorization ||
      config.headers.Authorization == "Bearer undefined"
    ) {
      const serializedState = sessionStorage.getItem(
        `oidc.user:${process.env.OIDC_AUTHORITY}:${process.env.CLIENT_ID}`
      );
      if (serializedState === null) {
        return undefined;
      }
      const appState = JSON.parse(serializedState);
      if (appState && appState.access_token) {
        config.headers.Authorization = "Bearer " + appState.access_token;
      }
    }
    let id = action.payload;
    let payload = [...id.payload];
    let submitToStoredProc = id.submitToStoredProc;
    try {
      const res = yield httpPost(
        !submitToStoredProc
          ? `${process.env.API_URL}/CRMData/deleteEdge`
          : `${process.env.API_URL}/CRMData/deleteEdge?submitToStoredProc=${submitToStoredProc}`,
        payload,
        config
      );
      yield put(
        dealTeamActions.delEdgeSucceeded({
          msg: EDGE_DELETE_SUCCESS,
          delEdgeSucceeded: true,
        })
      );
    } catch (e) {
      yield put(
        dealTeamActions.delEdgeFailed({
          msg: "Some error occured",
          delEdgeFailed: true,
        })
      );
    }
  }
}
