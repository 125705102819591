import React, { useState, useRef } from "react";
import "./styles.css";
import DatePicker from "react-multi-date-picker";
import PropTypes from "prop-types";

import InputIcon from "react-multi-date-picker/components/input_icon";
const M_DateRangeFilter = (props) => {
  let minDateValue = props?.minDate ?? "";
  let maxDateValue = props?.maxDate ?? "";
  let noOfDays = props?.noOfDays ?? "";
  const datePickerRef = useRef();
  const [values, setValues] = useState([props.startDate, props.endDate]);
  React.useEffect(() => {
    if (
      values &&
      values[0] &&
      values[0] !== props.startDate &&
      values[1] &&
      values[1] != props.endDate
    ) {
      datePickerRef.current.closeCalendar();
      props.filterChange(
        values[0].format("DD/MM/YYYY"),
        values[1] ? values[1].format("DD/MM/YYYY") : ""
      );
    }
  }, [values]);
  const today = new Date();
  const minDates = new Date(today);
  minDates.setDate(today.getDate() - noOfDays);
  return (
    <DatePicker
      ref={datePickerRef}
      render={<InputIcon />}
      editable={false}
      hideOnScroll
      multiple
      numberOfMonths={2}
      format="DD/MM/YYYY"
      rangeHover
      value={values}
      onChange={setValues}
      range
      minDate={minDateValue ? minDates : ""}
      maxDate={maxDateValue ? today : ""}
    ></DatePicker>
  );
};
M_DateRangeFilter.propTypes = {
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
};
export default M_DateRangeFilter;
