export const replaceSpecialCharacters = (text) => {
  const specialCharacters = /[\\/?*[\]:]+/g;
  const newText = text.replace(specialCharacters, "_");
  const finalText = newText.replace(/^[_]+|[_]+$/g, "");

  return finalText;
};
export function replaceCrmId(object, crmId, pageUrl) {
  // Iterate over the properties of the object
  for (const key in object) {
    if (object[key].toLowerCase() === "=crmid") {
      object[key] = crmId;
    }
    if (object[key].toLowerCase() === "=pageurl") {
      object[key] = pageUrl;
    }
  }

  return object;
}
