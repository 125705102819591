import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import { makeStyles } from "@material-ui/core/styles";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as esgActions from "../../../../../store/actions/esgActions";
import EsgQuestionnaireBody from "./EsgQuestionnaire/EsgQuestionnaireBody";
import {esgHeaderCss} from './Helper/Esg.css'
const useStyles = makeStyles(esgHeaderCss);

const EsgHeader = (props) => {
  const classes = useStyles();
  const [addQuestions, setAddQuestions] = useState(false);
  const [questionnaireShow, setQuestionnaireShow] = useState(false);
  const [editCrmId,setEditCrmId] = useState(null)
  let Questionnaire = [];
  React.useEffect(() => {
    props.getEsgRecord();
  }, []);

  const QuestionnaireName =
    props &&
    props.esgRecord &&
    props.esgRecord.data &&
    props.esgRecord.data &&
    Array.isArray(props.esgRecord.data)
      ? props.esgRecord.data.map((item) => ({
          ...item.surveyBaseInfo,
          id: item.id,
        }))
      : [];

  QuestionnaireName.map((item) => {
    Questionnaire.push({
      value: item.id,
      label: item.name,
    });
  });
  const newQuestionnaire = () => {
    setQuestionnaireShow(true);
   setEditCrmId(null)
  };
  const handleQuestionnaireChange = (event) => {
    setEditCrmId(event.value)
    setQuestionnaireShow(true)
  };
  const handleAddQuestionnaire = () => {
    setAddQuestions(true);
  };
  return (
    <>
      {questionnaireShow ? (
        <EsgQuestionnaireBody crmId = {editCrmId} />
      ) : (
        <div className={classes.root}>
          {addQuestions ? (
            <A_Select
              className={classes.inputcontrolDropdown}
              label="Questionnaire"
              id="slectreport"
              key="slectreport"
              helperText="select from existing questionnaires"
              native={true}
              variant="filled"
              options={Questionnaire}
              onChange={handleQuestionnaireChange}
            />
          ) : (
            ""
          )}

          <div>
            <Grid container>
              <Grid item xs={12}>
                <A_Button
                 
                  className={classes.questionStyle}
                  label="Select default questionnaire Value"
                  variant="outlined"
        
                />
              </Grid>
              <Grid item xs={12}>
                <A_Button
                  className={classes.questionStyle}
                  label="Create a new questionnaire from scratch"
                  variant="outlined"
                  onClick={() => newQuestionnaire()}
                />
              </Grid>
              <Grid item xs={12}>
                <A_Button
                  className={classes.questionStyle}
                  label="Review and Edit questionnaire "
                  variant="outlined"
                  onClick={handleAddQuestionnaire}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};
function mapStateToProps(state) {
  return {
    esgRecord: state.esgRecord,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(esgActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EsgHeader);
